import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/security-usecase.scss'
import { FaAngleLeft } from "react-icons/fa"
import IdeaMapImg from '../images/security-usecase/idea.png'

const DataProtectionProctorLaw = () => {
    return (
        <div className="security-usecase">
            <Layout>
                <Nav
                    isWhite={true}
                />

                <div className="px-5 pt-4">
                    <Link to="/security-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Risk Management</p></Link>
                </div>

                <div className="digital-usecase pb-5 pt-4">
                    <div className="bg-data-protection"></div>
                </div>


                <div className="container">
                    <h1 className="font-weight-bold text-center mt-md-5 big-text">helping a law firm protect their intellectual property</h1>

                    <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Data protection</span>law</p>

                    <div style={{ borderBottom: '5px solid #E41700' }}></div>
                </div>

                <div className="py-5 my-5"></div>

                <div className="bg-client-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>The Client</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>In a bid to protect their intellectual property, our client — a blue-chip law firm in Nigeria— reached out to us to help them prevent unauthorized usage, copying, printing, and forwarding of data/information within and outside the organization. There had been cases of information leaking to external parties, sensitive documents inexplicably turning up outside the organization, and ex-employees grudgingly sharing the organization’s strategic plans when they join competitor firms.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-solution-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5 pb-5">
                            <div className="col-md-6 mx-auto my-5">
                                <p className="font-weight-bold" style={{ fontSize: '1.5rem' }}>Our Solution</p>

                                <p className="text-secondary" style={{ fontSize: '1.15rem', lineHeight: '30px' }}>
                                We stepped in with policies to guide the handling of data between networks. We set up their network with safety nets to protect private data by prohibiting staff from sending sensitive information outside the network. We also infused software and hardware technologies to prevent unauthorised uploading and forwarding of private information in an unsafe manner.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 mx-auto">
                            <img src={IdeaMapImg} alt="Journey Map" className="img-fluid" />
                            <p className="text-secondary text-center mt-1">Ideation process to hack security process</p>
                        </div>
                    </div>
                </div>

                <div className="my-5 py-5"></div>

                <div className="bg-impact-usecase">
                    <div className="container">
                        <div className="row pt-5 mt-5">
                            <div className="col-md-6 my-5">
                                <p style={{ fontSize: '1.5rem' }} className="font-weight-bold">The Impact</p>

                                <ul>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Protected the intellectual property of the organization. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Ensured data compliance within business rules. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Sustained compliance to data regulatory standards across teams. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Prevented data loss on all levels. Maintained data confidentiality and safety. </p></li>
                                    <li><p style={{ fontSize: '1.15rem', lineHeight: '30px' }} className="text-secondary mb-1">Regulated data accessibility. Protected the integrity of computer networks.</p></li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row py-5 my-5">
                        <div className="col-md-5 mx-auto mb-5 text-center">
                            <Link to={`/contact`} state={{ path: 'risk' }}><button style={{ height: '48px' }} class="btn btn-red h-100 btn-red-black px-4 no-border-radius">Get started</button></Link>
                        </div>
                    </div>
                </div>
            </Layout >
        </div>
    )
}

export default DataProtectionProctorLaw
